import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    permissions: {
      saving: false,
      loading: false,
      data: [],
    },
    groups: [],
    users: [],
    sideBarSections: [
      {
        id: 1,
        displayText: "Admin",
      },
      {
        id: 5,
        displayText: "Manager",
      },
      {
        id: 2,
        displayText: "Provider",
      },
      {
        id: 3,
        displayText: "Client",
      },
    ],
    groupsUsers: {
      saving: false,
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
    loading: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_PAGINATION: (state, data) => {
      state.groupsUsers.pagination = data;
    },
    SET_GROUPS_USERS_LOADING: (state, data) => {
      state.groupsUsers.loading = data;
    },
    SET_GROUPS_USERS_SAVING: (state, data) => {
      state.groupsUsers.saving = data;
    },
    SET_PERMISSIONS_LOADING: (state, data) => {
      state.permissions.loading = data;
    },
    SET_PERMISSIONS_SAVING: (state, data) => {
      state.permissions.saving = data;
    },
    SET_PERMISSIONS_DATA: (state, data) => {
      state.permissions.data = data;
    },
  },
  actions: {
    getGroups: function ({ commit }, role_id) {
      commit("SET_STATE", { key: "loading", value: true });
      return new Promise((resolve) => {
        this.$http
          .get(`groups?role_id=${role_id}`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_STATE", {
                key: "groups",
                value: res.data.data,
              });
              resolve(res.data.data);
            }
            commit("SET_STATE", { key: "loading", value: false });
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            commit("SET_STATE", { key: "loading", value: false });
          });
      });
    },
    search: function ({ commit }, searchText) {
      commit("SET_STATE", { key: "loading", value: true });
      this.$http
        .get("search", { searchText })
        .then((result) => {
          commit("SET_STATE", { key: "loading", value: false });
          commit("SET_STATE", {
            key: "users",
            value: result.data.data,
          });
        })
        .catch((error) => {
          commit("SET_STATE", { key: "loading", value: false });
          if (!error.accessDenied) {
            Swal.fire({
              title: "Error",
              text: error.data.message,
              icon: "error",
            });
          }
        });
    },
    getGroupsUsers: function ({ commit, state }, payload) {
      let url = `groups/users?role_id=${payload.role_id}`;
      if (payload) {
        if (payload.limit) {
          url = `${url}&limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.username) {
          url = `${url}&username=${payload.username}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_GROUPS_USERS_LOADING", true);
      }
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload && payload.page) {
                commit("SET_PAGINATION", {
                  total: res.data.total,
                  current: res.data.current_page,
                  data: [
                    ...state.groupsUsers.pagination.data,
                    ...res.data.data,
                  ],
                });
              } else if (payload && payload.limit) {
                commit("SET_PAGINATION", {
                  total: res.data.total,
                  current: res.data.current_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_STATE", {
                  key: "data",
                  value: res.data,
                });
              }
              resolve(res.data.data);
            }
            commit("SET_GROUPS_USERS_LOADING", false);
          })
          .catch((err) => {
            commit("SET_GROUPS_USERS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
    updateUsersGroups: function ({ commit }, payload) {
      commit("SET_GROUPS_USERS_SAVING", true);
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(`groups/users`, {
            role_id: payload.role_id,
            users: payload.changedUsers,
          })
          .then(() => {
            commit("SET_GROUPS_USERS_SAVING", false);
            Swal.fire({
              text: "Users groups Updated Successfully",
              icon: "success",
            });
            return resolve(true);
          })
          .catch((err) => {
            commit("SET_GROUPS_USERS_SAVING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve(false);
            }
          });
      });
    },
    getPermissions: function ({ commit }, role_id) {
      commit("SET_PERMISSIONS_LOADING", true);
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`permissions?role_id=${role_id}`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_PERMISSIONS_DATA", res.data);
              commit("SET_PERMISSIONS_LOADING", false);
              return resolve(res.data.data);
            }
            commit("SET_PERMISSIONS_LOADING", false);
          })
          .catch((err) => {
            commit("SET_PERMISSIONS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve(false);
            }
          });
      });
    },
    updatePermissions: function ({ commit }, payload) {
      commit("SET_PERMISSIONS_SAVING", true);
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(`groups/permissions`, {
            role_id: payload.role_id,
            permissions: payload.changedPermissions,
          })
          .then((res) => {
            if (res.status == 201) {
              commit("SET_PERMISSIONS_SAVING", false);
              Swal.fire({
                text: "Permissions Updated Successfully",
                icon: "success",
              });
            }
            return resolve(true);
          })
          .catch((err) => {
            commit("SET_PERMISSIONS_SAVING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve(false);
            }
          });
      });
    },
  },
};
