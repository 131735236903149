import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    rooms: {
      loading: false,
      data: [],
    },
    recordings: {
      loading: false,
      data: [],
    },
    createRoomLoading: false,
    getRoomLoading: null,
    deleteRoomLoading: null,
    shareRecordingLoading: null,
  },
  mutations: {
    SET_ROOMS_LOADING: (state, data) => {
      state.rooms.loading = data;
    },
    SET_ROOMS_DATA: (state, data) => {
      state.rooms.data = data;
    },
    SET_CREATE_ROOM_LOADING: (state, data) => {
      state.createRoomLoading = data;
    },
    SET_GET_ROOM_LOADING: (state, data) => {
      state.getRoomLoading = data;
    },
    SET_DELETE_ROOM_LOADING: (state, data) => {
      state.deleteRoomLoading = data;
    },
    SET_SHARE_RECORDING_LOADING: (state, data) => {
      state.shareRecordingLoading = data;
    },
    UPDATE_ROOM: (state, data) => {
      const index = state.rooms.data.findIndex((room) => room.id === data.id);
      if (index != -1) {
        state.rooms.data[index] = data;
        state.rooms.data = [...state.rooms.data];
      }
    },
    SET_RECORDINGS_LOADING: (state, data) => {
      state.recordings.loading = data;
    },
    SET_RECORDINGS_DATA: (state, data) => {
      state.recordings.data = data;
    },
    UPDATE_SHARED_WITH_ARRAY: (state, data) => {
      const index = state.recordings.data.findIndex(
        (recording) => recording.path === data.path
      );
      if (index != -1) {
        state.recordings.data[index].shared_with = data.sharedWith;
        state.recordings.data = [...state.recordings.data];
      }
    },
  },
  actions: {
    getRooms: function ({ commit }, payload) {
      const vm = this;
      let url = "meetings";
      if (payload) {
        if (payload.displayMyRoomsOnly) {
          url = `${url}?my_rooms=true`;
        }
      }
      commit("SET_ROOMS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            commit("SET_ROOMS_DATA", res.data.data);
            commit("SET_ROOMS_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_ROOMS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createRoom: function ({ commit }, payload) {
      const vm = this;
      commit("SET_CREATE_ROOM_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post("meetings", payload).then(
          (res) => {
            commit("SET_CREATE_ROOM_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CREATE_ROOM_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getRoom: function ({ commit }, payload) {
      const vm = this;
      commit("SET_GET_ROOM_LOADING", payload.roomId);
      return new Promise((resolve) => {
        return vm.$http.get(`meetings/${payload.roomId}`).then(
          (res) => {
            commit("SET_GET_ROOM_LOADING", null);
            resolve(res);
          },
          (err) => {
            commit("SET_GET_ROOM_LOADING", null);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteRoom: function ({ commit }, roomId) {
      const vm = this;
      commit("SET_DELETE_ROOM_LOADING", roomId);
      return new Promise((resolve) => {
        return vm.$http.delete(`meetings/${roomId}`).then(
          (res) => {
            commit("SET_DELETE_ROOM_LOADING", null);
            resolve(res);
          },
          (err) => {
            commit("SET_DELETE_ROOM_LOADING", null);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getWherebyOrganizationUrl: function () {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get("meetings/organization").then(
          (res) => {
            resolve(res);
          },
          (err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getRecordings: function (context, payload) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`meetings/${payload.meetingId}/recordings`).then(
          (res) => {
            resolve(res);
          },
          (err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteRecording: function (context, payload) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`meetings/${payload.meetingId}/delete-recording`, payload.data)
          .then(
            (res) => {
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getAllRecordings: function ({ commit }) {
      commit("SET_RECORDINGS_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`meetings/recordings`).then(
          (res) => {
            commit("SET_RECORDINGS_LOADING", false);
            commit("SET_RECORDINGS_DATA", res.data.data);
            resolve(res);
          },
          (err) => {
            commit("SET_RECORDINGS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    shareRecording: function ({ commit }, payload) {
      commit("SET_SHARE_RECORDING_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`meetings/${payload.meetingId}/share-recording`, payload.data)
          .then(
            (res) => {
              commit("SET_SHARE_RECORDING_LOADING", false);
              resolve(res);
            },
            (err) => {
              commit("SET_SHARE_RECORDING_LOADING", false);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    updateRooms: function ({ commit }, payload) {
      commit("UPDATE_ROOM", payload);
    },
    updateSharedWithArray: function ({ commit }, payload) {
      commit("UPDATE_SHARED_WITH_ARRAY", payload);
    },
  },
};
