import router from "../../router/index";
import Swal from "sweetalert2";
import { remove } from "lodash";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      if (data) {
        this.$http
          .post(`users/${data.user_id}/address`, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });

              router.push({
                name: "patients.address",
                params: { id: data.patient_id },
              });
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire(
                "Error",
                (((err || {}).data || {}).error || {}).message || err,
                "error"
              );
            }
          });
      }
    },
    remove: function (context, payload) {
      this.$http
        .delete(`users/${payload.user}/address/${payload.id}`)
        .then((res) => {
          if (res.status == 200) {
            context.state.data = remove(context.state.data, function (n) {
              return n.id != payload.id;
            });

            Swal.fire({
              title: "Deleted",
              text: "Address Deleted",
              icon: "success",
            });
          }
        });
    },
    get: function (context, id) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      if (id) {
        this.$http.get(`users/${id}/address`).then((res) => {
          context.commit("SET_STATE", {
            key: "data",
            value: res.data.data,
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
      }
    },
  },
};
