import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    templates: [],
    clinicalNotes: [],
    isLoading: false,
    isSaving: false,
    categoryLoading: false,
    clinicalLoading: false,
    generateShareableLinkLoading: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getTemplates: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise((resolve) => {
        this.$http
          .get("form-templates")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "templates",
                value: res.data.data,
              });
              resolve(res.data.data);
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getTemplateById: function (context, id) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise((resolve) => {
        this.$http
          .get(`form-templates/${id}`)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data.data);
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    createUpdateTemplate: function (context, data) {
      if (data.id) {
        return new Promise((resolve) => {
          this.$http
            .put("form-templates/" + data.id, data)
            .then((res) => {
              if (res) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: "Form Template Updated Successfully",
                  icon: "success",
                });
              }
              resolve(res);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err.data ? err.data.message : err,
                  icon: "error",
                });
                resolve();
              }
            });
        });
      } else {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        return new Promise((resolve) => {
          this.$http
            .post("form-templates/", data)
            .then((res) => {
              if (res) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: "Form Template Created Successfully",
                  icon: "success",
                });
              }
              resolve(res);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err.data ? err.data.message : err,
                  icon: "error",
                });
                resolve();
              }
            });
        });
      }
    },
    deleteTemplate: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(`form-templates/${id}`)
          .then((res) => {
            if (res.data?.status == "ERROR") {
              Swal.fire({
                title: "Error",
                text: res.data.message,
                icon: "error",
              });
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data.message || "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    updateTemplateStatus: function (_, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`form-templates-hidden/${payload.id}`, payload)
          .then((res) => {
            if (res.data?.status == "ERROR") {
              Swal.fire({
                title: "Error",
                text: res.data.message,
                icon: "error",
              });
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data.message,
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    getTemplateCategories: function (context) {
      context.commit("SET_STATE", { key: "categoryLoading", value: true });
      return new Promise((resolve) => {
        this.$http
          .get("form-templates-category")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "categories",
                value: res.data.data,
              });
              resolve(res.data.data);
            }
            context.commit("SET_STATE", {
              key: "categoryLoading",
              value: false,
            });
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "categoryLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getClinicalNotesByPatientId: function (context, id) {
      let vm = this;
      context.commit("SET_STATE", { key: "clinicalLoading", value: true });
      return new Promise((resolve) => {
        if (!id) {
          return resolve([]);
        }
        vm.$http
          .get(`forms/?patient_id=${id}`)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data.data);
            }
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getBasicClinicalNotesByPatientId: function (context, id) {
      context.commit("SET_STATE", { key: "clinicalLoading", value: true });
      return new Promise((resolve) => {
        if (!id) {
          return resolve([]);
        }
        this.$http
          .get(`forms-basic/?patient_id=${id}`)
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "clinicalNotes",
                value: res.data.data,
              });
              resolve(res.data.data);
            }
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getBasicClinicalNotesAptIds: function (_, id) {
      let vm = this;
      return new Promise((resolve) => {
        if (!id) {
          return resolve([]);
        }
        vm.$http
          .get(`forms-basic-apt-id/?patient_id=${id}`)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    getClinicalNoteById: function (context, id) {
      context.commit("SET_STATE", { key: "clinicalLoading", value: true });
      return new Promise((resolve) => {
        if (!id) {
          return resolve([]);
        }
        this.$http
          .get(`forms/${id}`)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "clinicalLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data ? err.data.message : err,
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    CreateClinicalNote: function (context, data) {
      if (data.id) {
        return new Promise((resolve) => {
          this.$http
            .put(`forms/${data.id}`, data)
            .then((res) => {
              if (res.data) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: "Clinical Note Updated Successfully",
                  icon: "success",
                });
              }
              resolve(res.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err.data ? err.data.message : err,
                  icon: "error",
                });
                resolve();
              }
            });
        });
      } else {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        return new Promise((resolve) => {
          this.$http
            .post("forms/", data)
            .then((res) => {
              if (res) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: "Clinical Note Created Successfully",
                  icon: "success",
                });
              }
              resolve(res);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err.data ? err.data.message : err,
                  icon: "error",
                });
              }
              context.commit("SET_STATE", { key: "isSaving", value: false });
              resolve();
            });
        });
      }
    },
    deleteClinicalNote: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(`forms/${id}`)
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    toggleNoteLockStatus: function (_, task) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`forms-lock/${task.id}`, { locked: !task.locked })
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getUserById: function (_, id) {
      return new Promise((resolve) => {
        this.$http
          .get(`users/${id}`)
          .then((res) => {
            resolve((res.data || {}).data);
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getPatientById: function (_, id) {
      return new Promise((resolve) => {
        this.$http
          .get(`patients/${id}`)
          .then((res) => {
            resolve((res.data || {}).data);
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getAptById: function (_, id) {
      return new Promise((resolve) => {
        this.$http
          .get(`appointments/${id}`)
          .then((res) => {
            resolve((res.data || {}).data);
          })
          .catch(() => {
            resolve();
          });
      });
    },
    generateShareableLink: function ({ commit }, payload) {
      commit("SET_STATE", {
        key: "generateShareableLinkLoading",
        value: payload.id,
      });
      return new Promise((resolve) => {
        this.$http
          .post(`forms/${payload.id}/shareable-link`)
          .then((res) => {
            commit("SET_STATE", {
              key: "generateShareableLinkLoading",
              value: false,
            });
            resolve(res.data);
          })
          .catch((err) => {
            commit("SET_STATE", {
              key: "generateShareableLinkLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    getFormByToken: function (_, token) {
      return new Promise((resolve) => {
        this.$http
          .get(`public-forms/${token}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
};
