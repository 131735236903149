const GSO_QUESTIONS = [
  {
    key: "frustration",
    text: "Did the client exhibit any frustration during the session?",
    keyword: "Exhibited Frustration",
  },
  {
    key: "difficulties_with_problem_solving",
    text: "Did the client demonstrate any difficulties with problem solving during the session?",
    keyword: "Problem Solving Difficulty",
  },
  {
    key: "dizziness",
    text: "Did the client report or exhibit any symptoms of dizziness during the session?",
    keyword: "Reported Dizziness",
  },
  {
    key: "difficulties_with_sensory_stimuli",
    text: "Did the client report or exhibit any difficulties with sensory stimuli during the session?",
    note: "Difficulties with lighting, crowds or noise",
    keyword: "Sensory Difficulties",
  },
  {
    key: "memory",
    text: "Did the client demonstrate any difficulties with memory during the session?",
    keyword: "Memory Difficulties",
  },
  {
    key: "verbal_aggression",
    text: "Did the client become verbal aggression in any way during the session?",
    keyword: "Exhibited Verbal Aggression",
  },
  {
    key: "physical_aggression",
    text: "Did the client exhibit any physical aggression during the session?",
    keyword: "Exhibited Physical Aggression",
  },
  {
    key: "socially_inappropriate_behavior",
    text: "Was the client observed to have any socially inappropriate behavior during the session?",
    keyword: "Socially Inappropriate Behavior",
  },
  {
    key: "sexually_inappropriate_behavior",
    text: "Did the client demonstrate any sexually inappropriate behavior during the session?",
    keyword: "Sexually Inappropriate Behavior",
  },
  {
    key: "GSOQ_1",
    text: "Did the client experience any physical barriers during the session?",
    keyword: "Physical Barriers Identified",
  },
  {
    key: "GSOQ_2",
    text: "Did the client struggle with mobility during the session?",
    keyword: "Difficulties With Mobility",
  },
  {
    key: "GSOQ_3",
    text: "Did the client struggle to complete the session's activities(s) due to physical limitations?",
    keyword: "Physical Limitations Identified",
  },
  {
    key: "GSOQ_4",
    text: "Did the client struggle to engage and/or participate in the session due to physical limitations?",
    keyword: "Engagement Limited Due To Physical Limitations",
  },
  {
    key: "GSOQ_5",
    text: "Did the client struggle to engage and/or participate in the session due to reported and/or observed mental health difficulties?",
    note: "sadness, lack of motivation, reported or observed anxiety",
    keyword: "Mental Health Cause Of Not Engaging",
  },
  {
    key: "GSOQ_6",
    text: "Did the client demonstrate executive functioning difficulties?",
    note: "i.e. problem solving, sequencing, decision-making, impulsivity, difficulties with organizing things",
    keyword: "Executive Functioning Difficulties",
  },
  {
    key: "GSOQ_7",
    text: "Did the client require verbal prompting during session activities?",
    keyword: "Required Verbal Prompting",
  },
  {
    key: "GSOQ_8",
    text: "Did the client require gestural prompting during session's activities?",
    keyword: "Required Gestural Prompting",
  },
  {
    key: "GSOQ_9",
    text: "Did the client require both verbal and gestural prompting during session's activities?",
    keyword: "Verbal & Gestural Prompting",
  },
  {
    key: "GSOQ_10",
    text: "Client required 2 or more prompts during session's activity(s)",
    keyword: "2 Or More Prompts Required",
  },
  {
    key: "GSOQ_11",
    text: "Client required 3-5 prompts during the session's activities(s)",
    keyword: "3-5 Prompts Required",
  },
  {
    key: "GSOQ_12",
    text: "Client required 6-10 prompts during the session during the session's activity(s)",
    keyword: "6-10 Prompts Required",
  },
  {
    key: "GSOQ_13",
    text: "Client required 10+ prompts during the session's activity(s)",
    keyword: "10+ Prompts Required",
  },
  {
    key: "GSOQ_14",
    text: "Did the client require hand over hand prompting?",
    keyword: "Hand Over Hand Prompting Required",
  },
  {
    key: "GSOQ_15",
    text: "Did the client require any indirect prompting?",
    keyword: "Indirect Prompting Required",
  },
  {
    key: "GSOQ_16",
    text: "Did the client have suicidal ideations during the session?",
    keyword: "Suicidal Ideations",
  },
  {
    key: "GSOQ_17",
    text: "Did the client demonstrate any self-stimulation behaviour during the session?",
    keyword: "Self Stimulation Behaviour",
  },
  {
    key: "GSOQ_18",
    text: "Did the client demonstrate any self-injurious behaviour during the session?",
    keyword: "Self Injurious Behaviour",
  },
  {
    key: "GSOQ_19",
    text: "Did the client exhibit any avoidance like behaviour during the session?",
    keyword: "Avoidance Behaviour",
  },
  {
    key: "GSOQ_20",
    text: "Did the client exhibit any form of attention seeking behaviour (positive or negative) during the session?",
    keyword: "Attention Seeking Behaviour",
  },
  {
    key: "GSOQ_21",
    text: "Did the client struggle to communicate verbally during the session?",
    keyword: "Struggle W/ Verbal Communication",
  },
  {
    key: "GSOQ_22",
    text: "Did the client struggle to take direction during the session?",
    keyword: "Difficulty W/ Taking Direction",
  },
  {
    key: "GSOQ_23",
    text: "Did the client struggle with maintaining attention and/or focus during the session’s activities?",
    keyword: "Difficulties W/ Focus/Attention",
  },
  {
    key: "GSOQ_24",
    text: "Did the client exhibit or report any information or behaviour that was indicative of struggling with mental health?",
    keyword: "Mental Health Difficulties",
  },
];

export default {
  GSO_QUESTIONS,
};
