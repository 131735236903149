import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    companyProfile: {},
    companyLogo: "",
    isLoading: false,
    isLogoLoading: false,
    isSaving: false,
    customToolbar: [
      ["font", "bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ background: ["yellow", "red", "blue", "green", "purple", "brown"] }],
      ["clean"],
    ],
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getCompanyProfile: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get("companies")
          .then((res) => {
            if (res.status == 200) {
              let companyProfileData = res.data.data;
              // TODO check with backend side if there is
              // another way instead of getting a list of profiles
              if (res.data.data.length > 0) {
                companyProfileData = res.data.data[0];
              }
              if (companyProfileData.logo) {
                companyProfileData.logo =
                  companyProfileData.logo.split("?response")[0];
              }

              context.commit("SET_STATE", {
                key: "companyProfile",
                value: companyProfileData,
              });

              context.commit("SET_STATE", {
                key: "companyLogo",
                value: companyProfileData.logo,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              return resolve(companyProfileData);
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            return resolve(false);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            return resolve(false);
          });
      });
    },
    getCompanyProfileLogo: function (context) {
      context.commit("SET_STATE", { key: "isLogoLoading", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get("company-logo-name")
          .then((res) => {
            if (res.status == 200) {
              let companyProfileLogo = (
                ((res.data || {}).data || {}).logo || ""
              ).split("?response")[0];

              context.commit("SET_STATE", {
                key: "companyLogo",
                value: companyProfileLogo,
              });

              if (((res.data || {}).data || {}).logo) {
                res.data.data.logo = res.data.data.logo.split("?response")[0];
              }
              const valueToSave = {
                ...context.state.companyProfile,
                ...((res.data || {}).data || {}),
              };
              context.commit("SET_STATE", {
                key: "companyProfile",
                value: valueToSave,
              });
              context.commit("SET_STATE", {
                key: "isLogoLoading",
                value: false,
              });
              return resolve(valueToSave);
            }
            context.commit("SET_STATE", { key: "isLogoLoading", value: false });
            return resolve(false);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isLogoLoading", value: false });
            resolve(false);
          });
      });
    },

    updateSignaturePhoto: function (_, data) {
      let vm = this;
      let formData = new FormData();
      formData.append("signature", data.logo);
      return new Promise(function (resolve) {
        vm.$http
          .post(`companies/upload-signature/${data.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res && res.data && res.data.signature);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve();
          });
      });
    },
    updateLogoPhoto: function (_, data) {
      let vm = this;
      let formData = new FormData();
      if (data.logo) {
        formData.append("logo", data.logo);
      }
      if (data.second_logo) {
        formData.append("second_logo", data.second_logo);
      }
      return new Promise(function (resolve) {
        vm.$http
          .post(`companies/upload/${data.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (data.logo) {
              return resolve(res && res.data && res.data.data.logo);
            }
            if (data.second_logo) {
              return resolve(res && res.data && res.data.data.second_logo);
            }
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve();
          });
      });
    },
    updateBotLogo: function (_, data) {
      let vm = this;
      let formData = new FormData();
      if (data.bot_logo) {
        formData.append("bot_logo", data.bot_logo);
      }
      return new Promise(function (resolve) {
        vm.$http
          .post(`companies/upload-bot-logo/${data.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (data.bot_logo) {
              return resolve(res && res.data && res.data.data.bot_logo);
            }
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve();
          });
      });
    },
    updateCompanyProfile: function (context, data) {
      let vm = this;
      context.commit("SET_STATE", { key: "isSaving", value: true });
      const headers = new Headers({
        "X-Requested-With": "XMLHttpRequest",
      });
      return new Promise(function (resolve) {
        vm.$http
          .put(
            "companies/" + data.id,
            data,
            data.fd,

            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              Swal.fire({
                title: "",
                text: "Company Profile Data Updated Successfully",
                icon: "success",
              });
              let companyProfileData = res.data.data;
              // TODO check with backend side if there is
              // another way instead of getting a list of profiles
              if (res.data.data.length > 0) {
                companyProfileData = res.data.data[0];
              }
              if (companyProfileData.logo) {
                companyProfileData.logo =
                  companyProfileData.logo.split("?response")[0];
              }

              context.commit("SET_STATE", {
                key: "companyProfile",
                value: companyProfileData,
              });

              context.commit("SET_STATE", {
                key: "companyLogo",
                value: companyProfileData.logo,
              });
              return resolve(res.data);
            }
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
            return resolve();
          });
      });
    },

    ///Used only for the first time, if no company profile passed. TODO check with
    //backend if there is another way to upsert API instead of having 2
    createCompanyProfile: function (context, data = {}) {
      if (data.id == null) {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post("companies", data)
          .then((res) => {
            if (res && res.data && res.data.data) {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              Swal.fire({
                title: "",
                text: "Company Profile Data Updated Successfully",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Error",
                text: (res.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
          });
      }
    },
  },
};
