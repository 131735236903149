import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    get: function (ctx, provider) {
      ctx.commit("SET_STATE", { key: "isLoading", value: true });
      this.$http
        .get(`providers/${provider}/professions`)
        .then((res) => {
          ctx.commit("SET_STATE", { key: "data", value: res.data.data });
          ctx.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          ctx.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire("Error", err.statusText, "error");
          }
        });
    },
  },
};
