export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    remove: function (_, payload) {
      this.$http
        .delete(`patients/${payload.id}/documents/${payload.document}`)
        .then((res) => {
          //eslint-disable-next-line
          console.log(res);
        });
    },
    download: function (_, doc) {
      if (doc && !doc.isDownloading) {
        doc.isDownloading = true;
        this.$http.get(`documents/${doc.id}/download`).then((res) => {
          var fileLink = document.createElement("a");
          fileLink.href = res.data.url;
          fileLink.setAttribute("download", res.data.filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          doc.isDownloading = false;
        });
      }
    },
    get: function (context, id) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      if (id) {
        this.$http.get(`patients/${id}/documents`).then((res) => {
          let data = res.data.data.filter((d) => {
            d.isDownloading = false;
            return d;
          });

          context.commit("SET_STATE", {
            key: "data",
            value: data,
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
      }
    },
  },
};
