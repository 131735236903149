import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    sharedRecordings: {
      loading: false,
      data: [],
    },
  },
  mutations: {
    SET_SHARED_RECORDINGS_LOADING: (state, data) => {
      state.sharedRecordings.loading = data;
    },
    SET_SHARED_RECORDINGS_DATA: (state, data) => {
      state.sharedRecordings.data = data;
    },
  },
  actions: {
    getSharedRecordings: function ({ commit }, payload) {
      commit("SET_SHARED_RECORDINGS_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`users/${payload.userId}/shared-recordings`).then(
          (res) => {
            commit("SET_SHARED_RECORDINGS_LOADING", false);
            commit("SET_SHARED_RECORDINGS_DATA", res.data.data);
            resolve(res);
          },
          (err) => {
            commit("SET_SHARED_RECORDINGS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteSharedRecording: function (context, payload) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`users/${payload.userId}/delete-shared-recording`, payload.data)
          .then(
            (res) => {
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
  },
};
