import Echo from "laravel-echo";
import Pusher from "pusher-js/with-encryption";
import { config } from "../config.js";

export const initiateEcho = function () {
  config.loadConfig().then((configResult) => {
    const token = localStorage.getItem("ARSS_TOKEN");
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      cluster: "mt1",
      key: "dc62d72a-cd78-47a6-ba37-d5e4dfc0e6c0",
      wsHost:
        "c3-worker-59dcd31e-9a51-4d5f-8669-4950cc46ccc6.yhijji23.workers.dev",
      wsPort: 443,
      wssPort: 443,
      forceTLS: true,
      encrypted: true,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      auth: { headers: { Authorization: `Bearer ${token}` } },
      authEndpoint: `${configResult.api}broadcasting/auth`,
    });
  });
};
