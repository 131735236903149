import Swal from "sweetalert2";
import CONSTANT from "./constants";

export default {
  namespaced: true,
  state: {
    activityCategories: {
      loading: false,
      data: [],
    },
    questionnaire: {
      loading: false,
      data: [],
    },
    currentQuestionnaire: {
      loading: false,
      data: {},
    },
    questionnairesForReport: {
      loading: false,
      data: [],
    },
    mapParts: [
      "Front Left Head",
      "Front Right Head",
      "Front Neck",
      "Front Left Shoulder",
      "Front Right Shoulder",
      "Front Left Upper Arm",
      "Front Right Upper Arm",
      "Front Left Lower Arm",
      "Front Right Lower Arm",
      "Front Left Hand",
      "Front Right Hand",
      "Left Chest",
      "Right Chest",
      "Left Abdomen",
      "Right Abdomen",
      "Genitalia",
      "Front Left Thigh",
      "Front Right Thigh",
      "Left Shin",
      "Right Shin",
      "Front Left Foot",
      "Front Right Foot",
      "Back Left Head",
      "Back Right Head",
      "Back Neck",
      "Back Left Shoulder",
      "Back Right Shoulder",
      "Back Left Upper Arm",
      "Back Right Upper Arm",
      "Back Left Lower Arm",
      "Back Right Lower Arm",
      "Back Left Hand",
      "Back Right Hand",
      "Upper Left Back",
      "Upper Right Back",
      "Lower Left Back",
      "Lower Right Back",
      "Left Hip",
      "Right Hip",
      "Back Left Thigh",
      "Back Right Thigh",
      "Left Calf",
      "Right Calf",
      "Back Left Foot",
      "Back Right Foot",
      "Sacrum",
      "Left Ankle",
      "Right Ankle",
      "Left Knee",
      "Right Knee",
      "Left Eye",
      "Right Eye",
      "Left Bottom Foot",
      "Right Bottom Foot",
    ],
    GSOQuestions: CONSTANT.GSO_QUESTIONS,
    painTime: {
      1: "Morning",
      2: "Afternoon",
      3: "Night",
      4: "Morning & Afternoon",
      5: "Morning & Night",
      6: "Afternoon & Night",
      7: "All The Time",
    },
    saving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_QUESTIONNAIRE_DATA: (state, data) => {
      state.questionnaire.data = data;
    },
    SET_QUESTIONNAIRE_LOADING: (state, data) => {
      state.questionnaire.loading = data;
    },
    SET_ACTIVITY_CATEGORIES_DATA: (state, data) => {
      state.activityCategories.data = data;
    },
    SET_ACTIVITY_CATEGORIES_LOADING: (state, data) => {
      state.activityCategories.loading = data;
    },
    SET_CURRENT_QUESTIONNAIRE_LOADING: (state, data) => {
      state.currentQuestionnaire.loading = data;
    },
    SET_CURRENT_QUESTIONNAIRE_DATA: (state, data) => {
      state.currentQuestionnaire.data = data;
    },
    SET_QUESTIONNAIRE_FOR_REPORT_DATA: (state, data) => {
      state.questionnairesForReport.data = data;
    },
    SET_QUESTIONNAIRE_FOR_REPORT_LOADING: (state, data) => {
      state.questionnairesForReport.loading = data;
    },
  },
  actions: {
    getAllQuestionnaires: function ({ commit }, id) {
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_QUESTIONNAIRE_LOADING", true);
        if (id) {
          return vm.$http
            .get(`patients/${id}/questionnaire`)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_QUESTIONNAIRE_DATA", res.data.data);
                commit("SET_QUESTIONNAIRE_LOADING", false);
                return resolve(res.data.data);
              }
              commit("SET_QUESTIONNAIRE_LOADING", false);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_QUESTIONNAIRE_LOADING", false);
              return resolve();
            });
        }
        resolve();
      });
    },
    getQuestionnaire: function ({ commit }, id) {
      commit("SET_CURRENT_QUESTIONNAIRE_LOADING", true);
      const vm = this;
      return new Promise(function (resolve) {
        if (id) {
          return vm.$http
            .get(`questionnaire/${id}`)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_CURRENT_QUESTIONNAIRE_DATA", res.data.data);
                commit("SET_CURRENT_QUESTIONNAIRE_LOADING", false);
                return resolve(res.data.data);
              }
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                commit("SET_CURRENT_QUESTIONNAIRE_LOADING", false);
              }
              return resolve(false);
            });
        }
        commit("SET_CURRENT_QUESTIONNAIRE_LOADING", false);
        resolve();
      });
    },
    getQuestionnaireByAptId: function (_, id) {
      const vm = this;
      return new Promise(function (resolve) {
        if (id) {
          return vm.$http
            .get(`appointment-questionnaire/${id}`)
            .then((res) => {
              if (res.status == 200) {
                return resolve(res.data.data);
              }
            })
            .catch(() => {
              resolve();
            });
        }
        resolve();
      });
    },
    saveQuestionnaire: function ({ commit }, payload) {
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_STATE", {
          key: "saving",
          value: true,
        });
        if (payload) {
          return vm.$http
            .put(`questionnaire/${payload.id}`, payload.data)
            .then((res) => {
              if (res.status == 200) {
                Swal.fire({
                  text: "Questionnaire Saved Successfully",
                  icon: "success",
                });
              }
              commit("SET_STATE", {
                key: "saving",
                value: false,
              });
              return resolve(true);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err.data || {}).error || (err.response || {}).data || {})
                      .message || "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_STATE", {
                key: "saving",
                value: false,
              });
              return resolve(false);
            });
        }
        resolve();
      });
    },
    setCurrentQuestionnaire: function ({ commit }, data) {
      commit("SET_CURRENT_QUESTIONNAIRE_DATA", data);
    },
    setQuestionnairesForReport: function ({ commit }, data) {
      commit("SET_QUESTIONNAIRE_FOR_REPORT_DATA", data);
    },
    getQuestionnairesByPeriod: function ({ commit }, payload) {
      let url = `questionnaire`;
      if (payload) {
        if (payload.id) {
          url = `${url}?userId=${payload.id}`;
        }
        if (payload.start) {
          url = `${url}&start=${payload.start}`;
        }
        if (payload.end) {
          url = `${url}&end=${payload.end}`;
        }
        if (payload.activity_categories) {
          url = `${url}&activity_categories=${payload.activity_categories}`;
        }
      }
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_QUESTIONNAIRE_FOR_REPORT_LOADING", true);
        if (payload) {
          return vm.$http
            .get(url)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_QUESTIONNAIRE_FOR_REPORT_DATA", res.data.data);
                commit("SET_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
                return resolve(res.data.data);
              }
              commit("SET_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
              return resolve();
            });
        }
        resolve();
      });
    },
    getActivityCategories: function ({ commit }) {
      commit("SET_ACTIVITY_CATEGORIES_LOADING", true);
      const vm = this;
      return new Promise(function (resolve) {
        return vm.$http
          .get("activity-categories")
          .then((res) => {
            if (res.status == 200) {
              commit("SET_ACTIVITY_CATEGORIES_DATA", res.data.data);
              commit("SET_ACTIVITY_CATEGORIES_LOADING", false);
              return resolve(res.data.data);
            }
          })
          .catch((err) => {
            commit("SET_ACTIVITY_CATEGORIES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            return resolve(false);
          });
      });
    },
  },
};
