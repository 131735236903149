import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    save: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      if (data) {
        this.$http
          .put(`users/${data.id}`, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
          });
      }
    },
  },
};
