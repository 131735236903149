import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getBodyNotes: function ({ commit }, id) {
      const vm = this;
      commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      return new Promise((resolve) => {
        return vm.$http.get(`users/${id}/body-notes`).then(
          (res) => {
            commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          },
          (err) => {
            commit("SET_SESSIONS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    addBodyNote: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      return new Promise((resolve) => {
        vm.$http.post(`users/${payload.user_id}/body-notes`, payload).then(
          (res) => {
            commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          },
          (err) => {
            commit("SET_SESSIONS_LOADING", false);
            Swal.fire({
              title: "Error",
              text:
                err?.data?.error?.message ||
                err?.data?.message ||
                "Something went wrong...",
              icon: "error",
            });
            resolve(false);
          }
        );
      });
    },
    updateBodyNote: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      return new Promise((resolve) => {
        vm.$http
          .put(`users/${payload.user_id}/body-notes/${payload.id}`, payload)
          .then(
            (res) => {
              commit("SET_STATE", {
                key: "data",
                value: res.data.data,
              });
              commit("SET_STATE", {
                key: "isLoading",
                value: false,
              });
              resolve(res.data.data);
            },
            (err) => {
              commit("SET_SESSIONS_LOADING", false);
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          );
      });
    },
    deleteBodyNote: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      return new Promise((resolve) => {
        vm.$http
          .delete(`users/${payload.user_id}/body-notes/${payload.id}`)
          .then(
            (res) => {
              commit("SET_STATE", {
                key: "data",
                value: res.data.data,
              });
              commit("SET_STATE", {
                key: "isLoading",
                value: false,
              });
              resolve(res.data.data);
            },
            (err) => {
              commit("SET_SESSIONS_LOADING", false);
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          );
      });
    },
    getStatistics: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`patients/${id}/get-dashboard-statistics`)
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
            resolve(false);
          });
      });
    },
  },
};
