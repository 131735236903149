import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    get: function (ctx, id) {
      ctx.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      if (id) {
        this.$http.get(`providers/${id}/contacts`).then((res) => {
          ctx.commit("SET_STATE", {
            key: "data",
            value: res.data.data,
          });
          ctx.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
      }
    },
    delete: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .delete(
            `providers/${payload.providerId}/contacts/${payload.contactId}`
          )
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.contactId],
                });
                Swal.fire({
                  title: "Contact Deleted",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },

    update: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(
            `providers/${payload.providerId}/contacts/${payload.contact.id}`,
            payload.contact
          )
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                });
                Swal.fire({
                  title: "Contact Updated",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    connect: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post(`providers/${payload.providerId}/contacts`, payload.newContact)
          .then(
            (res) => {
              if (res.status == 200 || res.status == 201) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.newContact],
                });
                Swal.fire({
                  title: "Connected",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
  },
};
