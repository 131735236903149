export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    saveProviderNote: function (context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) {
          return reject("No Data");
        }
        context.commit("SET_STATE", {
          key: "isSaving",
          value: true,
        });
        this.$http
          .put(`providers/${payload.provider_id}/admin-note`, {
            admin_note: payload.admin_note,
          })
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });

              return resolve(res.data);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            return reject(err);
          });
      });
    },
  },
};
