import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    allContacts: {
      current: null,
      total: null,
      data: [],
    },
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getAll: function ({ commit, state }, payload = {}) {
      let url = `patients/all-contacts?`;
      if (payload.limit) {
        url = `${url}limit=${payload.limit}`;
      }
      if (payload.page) {
        url = `${url}&page=${payload.page}`;
      } else {
        commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
      }

      let vm = this;
      return new Promise(function (resolve) {
        vm.$http.get(url).then(
          (res) => {
            if (payload && payload.page) {
              commit("SET_STATE", {
                key: "allContacts",
                value: {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [...state.allContacts.data, ...res.data.data],
                },
              });
            } else if (payload && payload.limit) {
              commit("SET_STATE", {
                key: "allContacts",
                value: {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: res.data.data,
                },
              });
            }
            commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          },
          (err) => {
            commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  (err.data || err.response.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve();
          }
        );
      });
    },
    get: function (ctx, id) {
      ctx.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      if (id) {
        this.$http.get(`patients/${id}/contacts`).then((res) => {
          ctx.commit("SET_STATE", {
            key: "data",
            value: res.data.data,
          });
          ctx.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
      }
    },
    delete: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .delete(`patients/${payload.patientId}/contacts/${payload.contactId}`)
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.contactId],
                });
                Swal.fire({
                  title: "Contact Deleted",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    update: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(
            `patients/${payload.patientId}/contacts/${payload.contact.id}`,
            payload.contact
          )
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                });
                Swal.fire({
                  title: "Contact Updated",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    connect: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post(`patients/${payload.patientId}/contacts`, payload.newContact)
          .then(
            (res) => {
              if (res.status == 200 || res.status == 201) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.newContact],
                });
                Swal.fire({
                  title: "Connected",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
  },
};
