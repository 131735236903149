import Swal from "sweetalert2";
import router from "../router";
import * as dayjs from "dayjs";

export default {
  namespaced: true,
  state: {
    allReferralSources: {
      loading: false,
      data: [],
    },
    referralSources: {
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
    saving: false,
    currentReferralSource: {
      loading: false,
      data: {},
    },
    referralSourceClients: {
      loading: false,
      name: "",
      data: [],
    },
  },
  mutations: {
    SET_REFERRAL_SOURCES_LOADING: (state, data) => {
      state.referralSources.loading = data;
    },
    SET_REFERRAL_SOURCES_PAGINATION: (state, data) => {
      state.referralSources.pagination = data;
    },
    SET_CURRENT_REFERRAL_SOURCES_LOADING: (state, data) => {
      state.currentReferralSource.loading = data;
    },
    SET_CURRENT_REFERRAL_SOURCES_DATA: (state, data) => {
      state.currentReferralSource.data = data;
    },
    SET_SAVING: (state, data) => {
      state.saving = data;
    },
    SET_REFERRAL_SOURCE_CLIENTS_LOADING: (state, data) => {
      state.referralSourceClients.loading = data;
    },
    SET_REFERRAL_SOURCE_CLIENTS_NAME: (state, data) => {
      state.referralSourceClients.name = data;
    },
    SET_REFERRAL_SOURCE_CLIENTS_DATA: (state, data) => {
      if (data && data.length) {
        data = data.sort((a, b) => {
          if (dayjs(a.created_at).diff(dayjs(b.created_at)) < 0) {
            return 1;
          }
          if (dayjs(a.created_at).diff(dayjs(b.created_at)) > 0) {
            return -1;
          }
          return 0;
        });
      }
      state.referralSourceClients.data = data;
    },
    SET_ALL_REFERRAL_SOURCES_DATA: (state, data) => {
      state.allReferralSources.data = data;
    },
    SET_ALL_REFERRAL_SOURCES_LOADING: (state, data) => {
      state.allReferralSources.loading = data;
    },
  },
  actions: {
    get: function ({ state, commit }, payload) {
      let url = `referral-sources?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.referrerName) {
          url = `${url}&referrerName=${payload.referrerName}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_REFERRAL_SOURCES_LOADING", true);
      }
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload && payload.page) {
                commit("SET_REFERRAL_SOURCES_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [
                    ...state.referralSources.pagination.data,
                    ...res.data.data,
                  ],
                });
              } else if (payload && payload.limit) {
                commit("SET_REFERRAL_SOURCES_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_REFERRAL_SOURCES_PAGINATION", {
                  total: 0,
                  current: 0,
                  data: res.data.data,
                });
              }
              resolve(res.data.data);
            }
            commit("SET_REFERRAL_SOURCES_LOADING", false);
          })
          .catch((err) => {
            commit("SET_REFERRAL_SOURCES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    create: function ({ commit }, payload) {
      commit("SET_SAVING", true);
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("referral-sources", payload)
          .then((res) => {
            commit("SET_SAVING", false);
            if (res.status == 200 && !res.data.error) {
              Swal.fire({
                title: "",
                text: "Referral Source Created Successfully",
                icon: "success",
              });
              router.push({ name: "referral-sources" });
            } else {
              Swal.fire({
                title: "Validation Error",
                text: res.data.error.message,
                icon: "error",
              });
            }
            return resolve();
          })
          .catch((err) => {
            commit("SET_SAVING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            return resolve();
          });
      });
    },
    update: function ({ commit }, payload) {
      commit("SET_SAVING", true);
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`referral-sources/${payload.id}`, payload)
          .then((res) => {
            commit("SET_SAVING", false);
            if (res.status == 200 && !res.data.error) {
              Swal.fire({
                title: "",
                text: "Referral Source Updated Successfully",
                icon: "success",
              });
              router.push({ name: "referral-sources" });
            } else {
              Swal.fire({
                title: "Validation Error",
                text: res.data.error.message,
                icon: "error",
              });
            }
            commit("SET_SAVING", false);
            return resolve();
          })
          .catch((err) => {
            commit("SET_SAVING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            return resolve();
          });
      });
    },
    getReferralSource: function ({ commit }, payload) {
      commit("SET_CURRENT_REFERRAL_SOURCES_LOADING", true);
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`referral-sources/${payload.id}`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_CURRENT_REFERRAL_SOURCES_DATA", res.data.data);
              resolve(res.data.data);
            }
            commit("SET_CURRENT_REFERRAL_SOURCES_LOADING", false);
          })
          .catch((err) => {
            commit("SET_CURRENT_REFERRAL_SOURCES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getReferralSourceClients: function ({ commit }, payload) {
      commit("SET_REFERRAL_SOURCE_CLIENTS_LOADING", true);
      commit("SET_REFERRAL_SOURCE_CLIENTS_NAME", payload.name);
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`referral-source-clients/${payload.id}`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_REFERRAL_SOURCE_CLIENTS_DATA", res.data.data);
              resolve(res.data.data);
            }
            commit("SET_REFERRAL_SOURCE_CLIENTS_LOADING", false);
          })
          .catch((err) => {
            commit("SET_REFERRAL_SOURCE_CLIENTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getBasicReferralSources: function ({ commit }) {
      commit("SET_ALL_REFERRAL_SOURCES_LOADING", true);
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`basic-referral-sources`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_ALL_REFERRAL_SOURCES_DATA", res.data.data);
              resolve(res.data.data);
            }
            commit("SET_ALL_REFERRAL_SOURCES_LOADING", false);
          })
          .catch((err) => {
            commit("SET_ALL_REFERRAL_SOURCES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
  },
};
