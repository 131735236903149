import Swal from "sweetalert2";
import { groupBy } from "lodash";
export default {
  namespaced: true,
  state: {
    data: [],
    allMaxhours: [],
    isLoading: false,
    isSaving: false,
    budgetChartData: [],
    budgetSpending: {
      loading: null,
      data: 0,
    },
    budgetHistory: {
      loading: null,
      data: {},
    },
    exhaustedBudgetsCount: {
      loading: false,
      data: 0,
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_BUDGET_CHART_DATA: (state, data) => {
      state.budgetChartData = data;
    },
    SET_BUDGET_SPENDING_DATA: (state, data) => {
      state.budgetSpending.data = data;
    },
    SET_BUDGET_SPENDING_LOADING: (state, data) => {
      state.budgetSpending.loading = data;
    },
    SET_BUDGET_HISTORY_DATA: (state, data) => {
      state.budgetHistory.data = data;
    },
    SET_BUDGET_HISTORY_LOADING: (state, data) => {
      state.budgetHistory.loading = data;
    },
    SET_EXHAUSTED_BUDGETS_COUNT: (state, data) => {
      state.exhaustedBudgetsCount.data = data;
    },
    SET_EXHAUSTED_BUDGETS_COUNT_LOADING: (state, data) => {
      state.exhaustedBudgetsCount.loading = data;
    },
  },
  actions: {
    get: function (ctx, id) {
      ctx.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      if (id) {
        this.$http.get(`patients/${id}/maxhours`).then((res) => {
          let totals = {};
          const subMaxhours = res.data.data.filter(
            (item) => item.parent_maxhour_id != 0
          );
          subMaxhours.forEach((sub) => {
            if (!totals[sub.parent_maxhour_id]) {
              totals[sub.parent_maxhour_id] = sub.value;
            } else {
              totals[sub.parent_maxhour_id] += sub.value;
            }
          });
          const mainMaxhours = res.data.data.filter(
            (item) => item.parent_maxhour_id == 0
          );
          mainMaxhours.forEach((item) => {
            item.totalValue = item.value + (totals[item.service_id] || 0);
          });
          ctx.commit("SET_STATE", {
            key: "data",
            value: mainMaxhours,
          });
          ctx.commit("SET_STATE", {
            key: "allMaxhours",
            value: res.data.data,
          });

          ctx.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
      }
    },
    delete: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .delete(`patients/${payload.user_id}/maxhours/${payload.maxhoursId}`)
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.user_id],
                });
                Swal.fire({
                  title: "Record Deleted",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    update: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(
            `patients/${payload.user_id}/maxhours/${payload.maxhours.id}`,
            payload.maxhours
          )
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                });
                Swal.fire({
                  title: "Record Updated",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    addNewMaxHours: function (ctx, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post(`patients/${payload.patientId}/maxhours`, payload.newmaxhours)
          .then(
            (res) => {
              if (res.status == 200 || res.status == 201) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.newmaxhours],
                });
                Swal.fire({
                  title: "Added",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    getBudgetChartByPeriod: function ({ commit }, params) {
      const vm = this;
      let url = `patients/${params.id}/budget-charts-data`;
      if (params) {
        if (params.period_type) {
          url = `${url}?period_type=${params.period_type}`;
        }
        if (params.start) {
          url = `${url}&start=${params.start}`;
        }
        if (params.end) {
          url = `${url}&end=${params.end}`;
        }
      }
      return new Promise((resolve) => {
        return vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              commit(`SET_BUDGET_CHART_DATA`, res.data.data);
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    renew: function ({ commit, state }, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(
            `patients/${payload.user_id}/renew-budget/${payload.maxhours.id}`,
            payload.maxhours
          )
          .then(
            (res) => {
              if (res.status == 200) {
                commit("SET_STATE", {
                  key: "data",
                });

                if (payload.maxhours.is_exhausted) {
                  commit(
                    "SET_EXHAUSTED_BUDGETS_COUNT",
                    Math.max(0, state.exhaustedBudgetsCount.data - 1)
                  );
                }
                Swal.fire({
                  title: "Budget Renewal",
                  text: res.data.message,
                  icon: "success",
                });
              }
              resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    getSpendingBudget: function ({ commit }, params) {
      commit("SET_BUDGET_SPENDING_LOADING", params.service_id);
      const vm = this;
      let url = `patients/${params.userId}/budget/${params.budgetId}/spending`;
      return new Promise((resolve) => {
        return vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              commit(`SET_BUDGET_SPENDING_DATA`, res.data.spending_amount);
              commit("SET_BUDGET_SPENDING_LOADING", null);
              resolve(res.data);
            }
          })
          .catch((err) => {
            commit("SET_BUDGET_SPENDING_LOADING", null);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    getBudgetHistory: function ({ commit }, params) {
      commit("SET_BUDGET_HISTORY_LOADING", params.service_id);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(`patients/${params.user_id}/budget-history/${params.service_id}`)
          .then((res) => {
            if (res.status == 200) {
              const budgetHistory = res.data.data;
              const groupedBudgetHistory = groupBy(budgetHistory, "deleted_at");
              commit(`SET_BUDGET_HISTORY_DATA`, groupedBudgetHistory);
              commit("SET_BUDGET_HISTORY_LOADING", null);
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            commit("SET_BUDGET_HISTORY_LOADING", null);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    getExhaustedBudgetsCount: function ({ commit }, params) {
      commit("SET_EXHAUSTED_BUDGETS_COUNT_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(`patients/${params.userId}/exhausted-budgets-count`)
          .then((res) => {
            if (res.status == 200) {
              commit(`SET_EXHAUSTED_BUDGETS_COUNT`, res.data.data);
              commit("SET_EXHAUSTED_BUDGETS_COUNT_LOADING", false);
              resolve(res);
            }
          })
          .catch((err) => {
            commit("SET_EXHAUSTED_BUDGETS_COUNT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
  },
};
