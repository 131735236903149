import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    data: [],
    calendarUsers: [],
    permissions: [],
    groups: [],
    roles: [],
    users: [],
    usersWithRoles: [],
    usersInfo: [],
    isLoading: false,
    isSaving: false,
    tempData: [],
    usersWithRolesLoading: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    attachPermissions: function (context, data) {
      let vm = this;
      return new Promise((resolve) => {
        if (data.groupPermissions != null) {
          context.commit("SET_STATE", { key: "isSaving", value: true });
          vm.$http
            .put("groups/permissions/", { groups: data.groupPermissions })
            .then((res) => {
              if (res) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: `${
                    data.area == "Caregiver"
                      ? "Provider"
                      : data.area == "Customer"
                      ? "Client"
                      : data.area
                  }s Permissions Updated Successfully`,
                  icon: "success",
                });
                return resolve();
              }
              return resolve();
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                return resolve();
              }
            });
        } else {
          return resolve();
        }
      });
    },
    getAllPermissions: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });

      this.$http
        .get("permissions")
        .then((res) => {
          if (res.status == 200) {
            context.commit("SET_STATE", {
              key: "permissions",
              value: res.data.data,
            });
          }
          context.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },

    getGroups: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });

      this.$http
        .get("groups")
        .then((res) => {
          if (res.status == 200) {
            context.commit("SET_STATE", {
              key: "groups",
              value: res.data.data,
            });
          }
          context.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    getAllUsers: function (context) {
      let vm = this;
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise((resolve) => {
        vm.$http
          .get("users")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "users",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getUsersWithRoles: function (context) {
      context.commit("SET_STATE", {
        key: "usersWithRolesLoading",
        value: true,
      });
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get("users-with-roles")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "usersWithRoles",
                value: res.data.data,
              });
              resolve(res.data.data);
            }
            context.commit("SET_STATE", {
              key: "usersWithRolesLoading",
              value: false,
            });
          })
          .catch(() => {
            context.commit("SET_STATE", {
              key: "usersWithRolesLoading",
              value: false,
            });
            resolve();
          });
      });
    },
    getCalendarUsers: function (context) {
      let vm = this;
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise((resolve) => {
        vm.$http
          .get("calendar-users")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "calendarUsers",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getAllUsersInfo: function (context) {
      let vm = this;
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise((resolve) => {
        vm.$http
          .get("all-users-info")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "usersInfo",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    updateUsersRoles: function (context, userRolesList) {
      if (userRolesList != null) {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .put("groups/users/", { users: userRolesList })
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              Swal.fire({
                title: "",
                text: "Users Roles Updated Successfully",
                icon: "success",
              });
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
          });
      }
    },
  },
};
