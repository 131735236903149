import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isSaving: false,
    isLoading: false,
    allservices: [],
    parentServices: [],
    parentServicesBasic: [],
    userServices: [],
  },

  groupBy: function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },

  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getParentServices: function (context) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });

      this.$http
        .get(`services`)
        .then((res) => {
          context.commit("SET_STATE", {
            key: "data",
            value: res.data.data.filter(
              (item) => item.parent_service_id == null
            ),
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        })
        .catch((err) => {
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Server Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    getServices: function (context) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      this.$http
        .get(`services`)
        .then((res) => {
          context.commit("SET_STATE", {
            key: "allservices",
            value: res.data.data,
          });

          context.commit("SET_STATE", {
            key: "parentServices",
            value: res.data.data.filter(
              (item) => item.parent_service_id == null
            ),
          });

          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        })
        .catch((err) => {
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Server Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    create: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      const vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post("services", data)
          .then((result) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            resolve(result.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: ((err.data || {}).error || {}).message || "",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    attachServiceToClient: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      const vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post("attach-service", data)
          .then(() => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            resolve(true);
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err.response || {}).data || {}).message ||
                  ((err.data || {}).error || {}).message ||
                  "",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    detachServiceFromUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post(`detach-service`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Service Detached From User Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            reject(err);
          });
      });
    },
    getClientServices: function (context, id) {
      const vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`user-services/${id}`)
          .then((res) => {
            context.commit("SET_STATE", {
              key: "userServices",
              value: res.data,
            });
            return resolve(res.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              context.commit("SET_STATE", {
                key: "userServices",
                value: [],
              });
              return resolve(false);
            }
          });
      });
    },
    update: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .put(`services/${data.id}`, data)
          .then((result) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            resolve(result.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: ((err.data || {}).error || {}).message || "",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    getParentServicesNames: function (context) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });

      const vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`parent-services-basic`)
          .then((res) => {
            context.commit("SET_STATE", {
              key: "parentServicesBasic",
              value: res.data.data,
            });
            context.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            return resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err.data || {}).error || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
  },
};
