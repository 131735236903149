import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    activityCategories: {
      loading: false,
      data: [],
    },
    clientQuestionnaire: {
      loading: false,
      data: [],
    },
    clientAppointments: {
      loading: false,
      data: [],
    },
    currentClientQuestionnaire: {
      loading: false,
      data: {},
    },
    clientQuestionnairesForReport: {
      loading: false,
      data: [],
    },
    saving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_CLIENT_QUESTIONNAIRE_DATA: (state, data) => {
      state.clientQuestionnaire.data = data;
    },
    SET_CLIENT_QUESTIONNAIRE_LOADING: (state, data) => {
      state.clientQuestionnaire.loading = data;
    },
    SET_CLIENT_APPOINTMENTS_DATA: (state, data) => {
      state.clientAppointments.data = data;
    },
    SET_CLIENT_APPOINTMENTS_LOADING: (state, data) => {
      state.clientAppointments.loading = data;
    },
    SET_ACTIVITY_CATEGORIES_DATA: (state, data) => {
      state.activityCategories.data = data;
    },
    SET_ACTIVITY_CATEGORIES_LOADING: (state, data) => {
      state.activityCategories.loading = data;
    },
    SET_CURRENT_CLIENT_QUESTIONNAIRE_LOADING: (state, data) => {
      state.currentClientQuestionnaire.loading = data;
    },
    SET_CURRENT_CLIENT_QUESTIONNAIRE_DATA: (state, data) => {
      state.currentClientQuestionnaire.data = data;
    },
    SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_DATA: (state, data) => {
      state.clientQuestionnairesForReport.data = data;
    },
    SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_LOADING: (state, data) => {
      state.clientQuestionnairesForReport.loading = data;
    },
  },
  actions: {
    getAllClientQuestionnaires: function ({ commit }, userId) {
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_CLIENT_QUESTIONNAIRE_LOADING", true);
        if (userId) {
          return vm.$http
            .get(`patients/${userId}/client-questionnaires`)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_CLIENT_QUESTIONNAIRE_DATA", res.data.data);
                commit("SET_CLIENT_QUESTIONNAIRE_LOADING", false);
                return resolve(res.data.data);
              }
              commit("SET_CLIENT_QUESTIONNAIRE_LOADING", false);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_CLIENT_QUESTIONNAIRE_LOADING", false);
              return resolve(false);
            });
        }
        resolve();
      });
    },
    getClientQuestionnaire: function ({ commit }, appointmentId) {
      commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_LOADING", true);
      const vm = this;
      return new Promise(function (resolve) {
        if (appointmentId) {
          return vm.$http
            .get(`patients/${appointmentId}/client-questionnaire`)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_DATA", res.data.data);
                commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_LOADING", false);
                return resolve(res.data.data);
              }
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_LOADING", false);
              }
              return resolve(false);
            });
        }
        commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_LOADING", false);
        resolve();
      });
    },
    saveClientQuestionnaire: function ({ commit }, payload) {
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_STATE", {
          key: "saving",
          value: true,
        });
        if (payload) {
          return vm.$http
            .post(`patients/${payload.id}/client-questionnaire`, payload.data)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_DATA", res.data.data);
                Swal.fire({
                  text: "Questionnaire Saved Successfully",
                  icon: "success",
                });
              }
              commit("SET_STATE", {
                key: "saving",
                value: false,
              });
              return resolve(true);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err.data || {}).error || (err.response || {}).data || {})
                      .message || "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_STATE", {
                key: "saving",
                value: false,
              });
              return resolve(false);
            });
        }
        resolve();
      });
    },
    setCurrentClientQuestionnaire: function ({ commit }, data) {
      commit("SET_CURRENT_CLIENT_QUESTIONNAIRE_DATA", data);
    },
    setClientQuestionnairesForReport: function ({ commit }, data) {
      commit("SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_DATA", data);
    },
    getClientQuestionnairesByPeriod: function ({ commit }, payload) {
      let url = `patients/${payload.id}/client-questionnaires-by-period`;
      if (payload) {
        if (payload.start) {
          url = `${url}?start=${payload.start}`;
        }
        if (payload.end) {
          url = `${url}&end=${payload.end}`;
        }
        if (payload.activity_categories) {
          url = `${url}&activity_categories=${payload.activity_categories}`;
        }
      }
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_LOADING", true);
        if (payload) {
          return vm.$http
            .get(url)
            .then((res) => {
              if (res.status == 200) {
                commit(
                  "SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_DATA",
                  res.data.data
                );
                commit("SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
                return resolve(res.data.data);
              }
              commit("SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_CLIENT_QUESTIONNAIRE_FOR_REPORT_LOADING", false);
              return resolve();
            });
        }
        resolve();
      });
    },
    getActivityCategories: function ({ commit }) {
      commit("SET_ACTIVITY_CATEGORIES_LOADING", true);
      const vm = this;
      return new Promise(function (resolve) {
        return vm.$http
          .get("activity-categories")
          .then((res) => {
            if (res.status == 200) {
              commit("SET_ACTIVITY_CATEGORIES_DATA", res.data.data);
              commit("SET_ACTIVITY_CATEGORIES_LOADING", false);
              return resolve(res.data.data);
            }
          })
          .catch((err) => {
            commit("SET_ACTIVITY_CATEGORIES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            return resolve(false);
          });
      });
    },
    getClientAppointments: function ({ commit }, userId) {
      const vm = this;
      return new Promise(function (resolve) {
        commit("SET_CLIENT_APPOINTMENTS_LOADING", true);
        if (userId) {
          return vm.$http
            .get(`patients/${userId}/client-appointments`)
            .then((res) => {
              if (res.status == 200) {
                commit("SET_CLIENT_APPOINTMENTS_DATA", res.data.data);
                commit("SET_CLIENT_APPOINTMENTS_LOADING", false);
                return resolve(res.data.data);
              }
              commit("SET_CLIENT_APPOINTMENTS_LOADING", false);
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    ((err || {}).data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              commit("SET_CLIENT_APPOINTMENTS_LOADING", false);
              return resolve();
            });
        }
        resolve();
      });
    },
  },
};
